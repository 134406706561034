import { client } from "./api/sdk.gen";
export * from "./api/schemas.gen";
export * from "./api/sdk.gen";
export * from "./api/types.gen";

let BASE: string;
if (process.env.NEXT_PUBLIC_BYOC_MODE_ENABLED === "true") {
  // will be replaced by the real URL in the runtime by the proxy
  if (typeof window !== "undefined") {
    BASE = "";
  } else {
    BASE = process.env.BACKEND_URL ?? "";
    client.setConfig({
      headers: {
        authorization: process.env.NEXT_PUBLIC_REAL_TOKEN ? `Bearer ${process.env.NEXT_PUBLIC_REAL_TOKEN}` : undefined,
      },
      credentials: "include",
    })
    client.interceptors.request.use(async (config) => {
      const { headers } = await import("next/headers");
      const cookie = (await headers()).get("cookie");
      if (!cookie) {
        return config;
      }
      // @ts-ignore
      const session = cookie!
        .split(";")
        .find((c) => c.trim().startsWith("session="))
        ?.split("=")[1]
        .trim();
      if (session) {
        config.headers.set('Cookie', `session=${session}`)
      }

      return config;
    });
    client.interceptors.response.use(async (response) => {
      if (!response.ok) {
        console.error(
          "API error when fetching",
          response.url,
          response.status,
          response.statusText,
        );
      }
      return response;
    });
  }
} else {
  if (typeof window === "undefined") {
    BASE =
      process.env.NEXT_PUBLIC_REAL_BACKEND_URL ??
      process.env.NEXT_PUBLIC_BACKEND_URL ??
      "";
    client.setConfig({
      headers: {
        authorization: process.env.NEXT_PUBLIC_REAL_TOKEN ? `Bearer ${process.env.NEXT_PUBLIC_REAL_TOKEN}` : undefined,
      },
      credentials: "include",
    })
    client.interceptors.request.use(async (config) => {
      const { headers } = await import("next/headers");
      const cookie = (await headers()).get("cookie");
      if (!cookie) {
        return config;
      }
      // @ts-ignore
      const session = cookie!
        .split(";")
        .find((c) => c.trim().startsWith("session="))
        ?.split("=")[1]
        .trim();
      if (session) {
        config.headers.set('Cookie', `session=${session}`)
      }

      return config;
    });
    client.interceptors.response.use(async (response) => {
      if (!response.ok) {
        console.error(
          "API error when fetching",
          response.url,
          response.status,
          response.statusText,
        );
      }
      return response;
    });
  } else {
    BASE =
      localStorage.getItem("openapi.base") ??
      process.env.NEXT_PUBLIC_REAL_BACKEND_URL ??
      process.env.NEXT_PUBLIC_BACKEND_URL ??
      "";
    const TOKEN =
      localStorage.getItem("openapi.token") ??
      process.env.NEXT_PUBLIC_REAL_TOKEN ??
      undefined;
    client.setConfig({
      headers: {
        authorization: TOKEN ? `Bearer ${TOKEN}` : undefined,
      },
    })

    // fixme: remove this when the upstream issue is fixed
    client.interceptors.response.use(async (response) => {
      if (response.headers.get('Content-Type') === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const headers = new Headers(response.headers);
        headers.set('Content-Type', 'application/octet-stream');
        return new Response(response.body, {
          ...response,
          headers,
        });
      }
      return response;
    })
  }
}

client.setConfig({
  ...client.getConfig(),
  baseUrl: BASE,
  credentials: "include",
})

export { client };
